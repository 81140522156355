/* COLORS */
$color-dark-gray: #333E48;
$color-darkest-gray: #1E2932;
$color-light-gray: #697077;
$color-xlight-gray: #D0D0D0;
$color-gray: #9C9C9C;
$color-green: #469D7C;
$color-orange: #EE7600;
$color-yellow: #F5A623;
$color-blue: #0078D3;
$color-light-blue: rgb(137, 180, 212);

/* FONTS */
$font-roboto: "Roboto", Roboto, Arial, sans-serif;
