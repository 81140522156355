@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}

@include keyframes(fadein) {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@include keyframes(fadeout) {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.snackbar-container {
  display: flex;
  justify-content: center;

  .snackbar {
    visibility: hidden;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    bottom: 30px;
    font-size: 1rem;

    &.show {
      visibility: visible; /* Show the snackbar */
      /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
     However, delay the fade out process for 2.5 seconds */
     @include animation('fadein 0.5s, fadeout 0.5s 2.5s');
    //  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    //  animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }
  }
}

/* Animations to fade the snackbar in and out */
// @-webkit-keyframes fadein {
//   from {bottom: 0; opacity: 0;} 
//   to {bottom: 30px; opacity: 1;}
// }

// @keyframes fadein {
//   from {bottom: 0; opacity: 0;}
//   to {bottom: 30px; opacity: 1;}
// }

// @-webkit-keyframes fadeout {
//   from {bottom: 30px; opacity: 1;} 
//   to {bottom: 0; opacity: 0;}
// }

// @keyframes fadeout {
//   from {bottom: 30px; opacity: 1;}
//   to {bottom: 0; opacity: 0;}
// }

