@import "../../vars";

.ResetPassword {
    background: $color-dark-gray;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;

    header#search-device-header {
        z-index: 1001;
        background: $color-dark-gray;
        width: 100%;
        position: relative;
        padding: 21px 0;
        box-sizing: border-box;
        color: #fff;
        font-weight: 500;
        display: flex;

        .container {
            justify-content: space-between;
        }

        .logo {
            font-size: 1.875em;
            font-family: Roboto;
            font-weight: 300;
            line-height: 35px;
            display: flex;
            align-items: center;

            img {
                height: 35px;
                width: 35px;
                display: inline-block;
                margin-right: 10px;
            }
        }

        .toolbar {
            .settings {
                .settings-menu {
                    z-index: 100;
                    position: absolute;
                    right: 0;
                    top: 58px;
                    background: $color-darkest-gray;
                    font-size: 1.625em;
                    border-radius: 0 0 10px 10px;
                    box-shadow: 0 2px 4px rgba(#000, 0.5), 0 2px 4px inset rgba(#000, 0.5);
                    overflow: hidden;
                    opacity: 0;
                    height: 0;
                    transition: all .3s ease-in-out;

                    ul {
                        list-style: none;
                        padding: .65em 1em .75em;
                        margin: 0;
                        width: 320px;
                        font-weight: 300;

                        li {
                            display: block;
                            line-height: 36px;
                            cursor: pointer;

                            a {
                                display: block;
                                width: 100%;
                                color: #fff;
                                text-decoration: none;
                                transition: color .3s ease-in-out;

                                &:hover {
                                    color: $color-orange;
                                }
                            }
                        }
                    }
                }

                span {
                    font-size: 2em;
                    transition: transform .25s ease-in-out;
                    cursor: pointer;

                    &:hover {
                        transform: rotate(60deg);
                        color: $color-orange;
                    }

                    &:active {
                        transform: rotate(60deg) scale(0.9);
                    }
                }

                &.open {
                    span[class*="icon-"] {
                        color: #fff;

                        &:hover {
                            transform: rotate(60deg);
                            color: $color-orange;
                        }

                        &:active {
                            transform: rotate(60deg) scale(0.9);
                        }
                    }

                    .settings-menu {
                        opacity: 1;
                        height: auto;
                    }
                }
            }
        }
    }

    .back {
        width: 100%;
        height: 70px;
        background-color: #1F988A;
        box-shadow: 0 1px 2px rgba(#000, 0.5);
        border-top: 1px solid #fff;
        color: #fff;
        z-index: 10;
        transition: opacity .3s ease-in-out;
        position: relative;

        a {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 100;
        }

        &:hover {
            opacity: .9;

            .wide-container {
                &:before {
                    transform: translateX(-15px);
                }
            }
        }

        .wide-container {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .h1 {
                color: #fff;
            }

            &:before {
                border-width: 13px 11px 13px 7px;
                border-color: transparent rgb(255, 255, 255) transparent transparent;
                border-style: solid;
                border-image: initial;
                display: block;
                width: 0;
                height: 0;
                content: '';
                // content: '\004f';
                //display: inline-block;
                //font-family: 'helicons';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                left: 30px;
                font-size: 2em;
                transition: transform .3s ease-in-out;

                @media (max-width: 960px) {
                    left: 10px;
                }
            }
        }
    }

    .profile-block {
        background: #4B545C;
        height: 100%;
        padding: 45px 90px 0;

        form {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            width: 100%;

            .error {
                font-size: 1.2em;
                margin-bottom: 20px;
                color: red;
            }

            .full-width-block {
                width: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;

                input[type="password"] {
                    width: 100%;
                    margin: 10px auto 26px;
                    clear: both;
                    display: block;
                    line-height: 1;
                    position: relative;
                    font-weight: 300;
                    border-radius: 10px;
        
                    &::placeholder {
                        font-size: 0.8em;
                    }
                }

                label {
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 20px;
                }
            }

            button,
            input[type="submit"],
            input[type="button"] {
                width: 300px;
                margin-bottom: 20px;
            }
        }
    }

}