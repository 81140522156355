@font-face {
    font-family: 'Roboto';
    src: url("./fonts/Roboto-Medium.eot");
    src: local("Roboto Medium"), local("Roboto-Medium"), url("./fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/Roboto-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url("./fonts/Roboto-Light.eot");
    src: local("Roboto Light"), local("Roboto-Light"), url("./fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Roboto-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url("./fonts/Roboto-Regular.eot");
    src: local("Roboto Regular"), local("Roboto-Regular"), url("./fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Roboto-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Italic';
    src: url("./fonts/Roboto-LightItalic.eot");
    src: local("Roboto Light Italic"), local("Roboto-LightItalic"), url("./fonts/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Roboto-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'helicons';
    src: url("./fonts/helicons.eot");
    src: url("./fonts/helicons.eot?#iefix") format("embedded-opentype"), url("./fonts/helicons.woff") format("woff"), url("./fonts/helicons.svg#helicons") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class*='icon-']:before {
    display: inline-block;
    font-family: 'helicons';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-flash:before {
    content: '\0041';
}

.icon-gear:before {
    content: '\0042';
}

.icon-plus:before {
    content: '\0043';
}

.icon-size:before {
    content: '\0044';
}

.icon-filter:before {
    content: '\0045';
}

.icon-close:before {
    content: '\0046';
}

.icon-info:before {
    content: '\0047';
}

.icon-headset:before {
    content: '\0048';
}

.icon-calendar:before {
    content: '\0049';
}

.icon-clipboard:before {
    content: '\004a';
}

.icon-android-clipboard:before {
    content: '\004b';
}

.icon-check:before {
    content: '\004c';
}

.icon-search:before {
    content: '\004d';
}

.icon-dots:before {
    content: '\004e';
}

.icon-back-arrow:before {
    content: '\004f';
}

.icon-edit:before {
    content: '\0050';
}

.icon-warning:before {
    content: '\0051';
}

.icon-checkbox:before {
    content: '\0052';
}

.icon-checkbox-checked:before {
    content: '\0053';
}

.icon-close-thin:before {
    content: '\0054';
}

.icon-eye:before {
    content: '\0055';
}

[class*='icon-'] {
    display: inline-block;
    line-height: 0;
}
