@import "fonts";
@import "vars";

html {
    height: 100%;
    zoom: 75%;
    font-size: 16px;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: $font-roboto;
    font-weight: 300;
    font-size: 1rem;
    position: relative;

    &.grey {
        background-color: #D3D5DB;
    }

    &.overlay {
        overflow: hidden;
    }
}

#root {
    min-height: 100%;
}

* {
    box-sizing: inherit;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
        -webkit-box-shadow: none;
        border-radius: 10px;
        background: #d8d8d8;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: none;
        -webkit-box-shadow: none;
        background: #aaaaaa;
    }
}

/* Helpers */
.hide {
    display: none !important;
    visibility: hidden;
    opacity: 0;
}

*[class*="icon-"] {
    &.green {
        color: $color-green;
    }

    &.red {
        color: #f00;
    }

    &.orange {
        color: $color-orange;
    }

    &.gray {
        color: $color-light-gray;
    }
}

*[class*="third-party-icon"] {
    &.green {
        color: $color-green;
    }

    &.red {
        color: #f00;
    }

    &.yellow {
        color: $color-yellow;
    }

    &.gray {
        color: $color-light-gray;
    }
}

/* Forms */
input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], input[type="button"], input[type="range"], input[type="time"], input[type="url"], input[type="submit"], input[type="search"], input[type="file"], input[type="image"], input[type="date"], input[type="color"], button {
    font-size: 1.25em;
    padding: .5em .75em;
    height: 51px;
    display: inline-block;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    line-height: 1;

    &:focus {
        outline: none;
    }

    &.big-radius {
        border-radius: 10px;
    }

    &.round {
        border-radius: 100px;
    }

    &::placeholder {
        color: #BCBCBC;
        font-weight: 300;
    }
}

.rows-2 {
    display: flex;
    flex-wrap: wrap;

    & > * {
        width: 50%;
    }

    @media (max-width: 640px) {
        & > * {
            width: 100%;
        }
    }
}

/* Buttons */
.btn {
    transition: filter .15s linear, box-shadow .15s ease-in-out, transform .15s ease-in-out;
    cursor: pointer;

    &:hover:enabled {
        filter: saturate(120%);
    }
    
    &:active:enabled {
        filter: saturate(80%)
    }

    &:focus {
        outline: none;
    }

    /* Colors */
    &.yellow {
        background: $color-yellow;
        color: #fff;
    }

    &.green {
        background: #1f988a;
        color: #fff;
    }

    &.orange {
        background: $color-orange;
        color: #fff;
    }

    &.blue {
        background: $color-blue;
        color: #fff;
    }

    &.grey {
        background: $color-xlight-gray;
        color: #fff;
    }
    
    &.gray-m {
        background: $color-gray;
        color: #fff;
    }

    &.red {
        background: #D0021B;
        color: #fff;
    }

    &.cancel {
        background: $color-xlight-gray;
        color: $color-light-gray;
    }

    /* Add shadow */
    &.shadow {
        box-shadow: 0 2px 4px rgba(#000, 0.5);

        &:hover:enabled {
            box-shadow: 0 7px 5px -3px rgba(#000, 0.35);
            transform: translateY(-2px);
        }

        &:active:enabled {
            transform: translateY(1px);
            box-shadow: 0 1px 2px rgba(#000, 0.5);
        }
    }

    &.small {
        font-size: 1rem;
        text-transform: uppercase;
        height: 40px;
        padding: 10px 25px;
        width: 90%;
        max-width: 270px;
        margin: 0 auto;
    }

    & .plus {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;

        &:before {
            content: '\0043';
            display: inline-block;
            font-family: 'helicons';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.2em;
            margin-right: .3em;
            transform: translateY(-1px);
            transition: transform .25s ease-in-out;
        }
    }

    &:disabled {
        background: #D5D5D5 !important;
        color: #fff !important;
        cursor: default;
    }
}

/* Text */
h1, .h1 {
    font-size: 1.5em;
    font-weight: 500;
    color: #313E49;
}

h3 {
    font-size: 1.25em;
    font-weight: 500;
}

strong {
    font-weight: 500;
    display: inline;
}

/* Structure */
header, footer {
    flex: none;
}

#content {
    flex: 1 0 auto;
    box-sizing: border-box;
    width: 100%;
}


.container {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    padding: 0 15px;
    position: relative;
}

.wide-container {
    @extend .container;

    box-sizing: border-box;
}

footer {
    height: 85px;
    width: 100%;
    background-image: url("./assets/img/logo-big-grey.svg");
    background-size: 181px 35px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #D3D5DB;
    flex: 0 0 auto;
}

.overlay-bg {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: rgba(#333E48, 0.7);

    &.show {
        display: flex;
    }

    &.hide {
        display: none;
    }
}
